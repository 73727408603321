import Vue from 'vue'
import * as markerjs2 from 'markerjs2'
import GUID from '../../js/guid'
import imageCompression from 'browser-image-compression'
import VeeValidate from 'vee-validate'
import confirm from '../confirm/confirm.vue'

import { Storage } from 'aws-amplify'

Vue.use(VeeValidate)

export default {
  name: 'multiCreate',

  components: {
    confirm: confirm
  },

  data: () => ({
    loading: true,
    dataJSON: {},
    issues: [],
    imageDialog: false,
    imageSrc: null,
    description: '',
    parentId: null,
    areaId: null,
    step: 1,
    toggleType: 1,
    emergency: false,
    issueList: false,
    issueListObject: {},
    typeSelected: false,
    locationSelected: false,
    issueTypes: [
      { value: 'Reactive' },
      { value: 'Emergency' },
      { value: 'issueList' }
    ]
  }),

  computed: {
    locations () {
      return this.$store.getters['config/getSelectedLookup']('location')
    },
    internals () {
      const internalsData = this.$store.getters['config/getSelectedLookup']('internal')
      internalsData.forEach(internal => {
        internal.value.toUpperCase()
      })
      return internalsData
    },
    issueListName () {
      let issueTitle = ''
      const parent = this.allIssues.filter(x => x.isParent === true)
      if (parent.length === 1) {
        issueTitle = parent[0].title
        this.issueListObject = parent[0]
      }
      return issueTitle
    },
    externals () {
      return this.$store.getters['config/getSelectedLookup']('external')
    },
    areas () {
      return this.$store.getters['areas/areas']
    },
    allIssues () {
      if (this.$route.params.id) {
        let areaIssues = this.$store.getters['areas/areaIssues']
        if (this.search) {
          areaIssues = areaIssues.filter(issue => {
            if (issue.description) {
              return issue.description.toLowerCase().includes(this.search.toLowerCase())
            } else {
              return false
            }
          })
        }

        return areaIssues
      } else {
        let issues = this.$store.getters['issues/issues']

        if (this.search) {
          issues = issues.filter(issue => {
            if (issue.description) {
              return issue.description.toLowerCase().includes(this.search.toLowerCase()
              )
            } else {
              return false
            }
          })
        }

        return issues
      }
    }
  },

  async mounted () {
    this.$store.commit('session/setIsDirty', true)
    await this.getAreas()
    await this.getIssues()
    if (this.$route.query.areaId) {
      await this.$store.dispatch('areas/retrieveArea', this.$route.query.areaId)
    }

    if (this.areas.length === 1) {
      this.areaId = this.areas[0].id
    }

    if (this.$route.query.areaId) {
      this.areaId = this.$route.query.areaId
    }

    if (this.$route.query.parentId) {
      this.parentId = this.$route.query.parentId
    } else {
      this.parentId = null
    }

    this.loading = false
  },

  beforeDestroy () {
    this.$store.commit('session/setIsDirty', false)
  },

  methods: {
    getAreas: async function () {
      this.loading = true
      try {
        await this.$store.dispatch('areas/retrieveAreas')
      } catch (err) {
        this.$snotify.error(err.message)
        this.loading = false
      }
    },
    getIssues: async function () {
      this.loading = true
      try {
        await this.$store.dispatch('issues/retrieveIssues')
      } catch (err) {
        this.$snotify.error(err.message)
        this.loading = false
      }
    },
    exitIssue () {
      if (this.$store.getters['session/getCognitoGroup']('user')) {
        this.$router.push('/issues')
      } else {
        if (this.$route.query.areaId && this.$route.query.areaId) {
          this.$router.push(`/developments/${this.$route.query.areaId}/issues`)
        } else {
          this.$router.push('/developments')
        }
      }
    },
    /**
     * Set up the location of the issues.
     * @param {*} prop
     * @param {*} value
     */
    setIssueProperty (prop, value) {
      if (this.dataJSON.internal !== undefined && value === 'EXT') {
        delete this.dataJSON.internal
      }
      if (this.dataJSON.external !== undefined && value === 'PRO') {
        delete this.dataJSON.external
      }
      if (this.dataJSON.location !== value) {
        this.locationSelected = false
      }
      if (this.dataJSON.location && this.dataJSON.external && this.dataJSON.internal) {
        this.locationSelected = true
      }
      this.$set(this.dataJSON, prop, value)
      if (prop === 'external' || prop === 'internal') {
        this.locationSelected = true
      }
    },
    setIssueType (prop, value) {
      if (value === 'Reactive') {
        this.emergency = false
        this.issueList = false
        this.$set(this.issues, prop, value)
        this.typeSelected = true
      }
      if (value === 'Emergency') {
        this.emergency = true
        this.issueList = false
        this.$set(this.issues, prop, value)
        this.typeSelected = true
      }
      if (value === '14 Day List') {
        this.issueList = true
        this.emergency = false
        this.$set(this.issues, prop, value)
        this.typeSelected = true
      }
    },
    /**
     * Open the file picker.
     */
    addIssue () {
      this.$refs.file.click()
    },
    async onFilePicked (e) {
      const file = e.target.files[0]
      const scope = this
      const data = await scope.readFile(file)
      this.imageSrc = data
      this.imageDialog = true
      // Next tick to be sure the dialog has opened before marker area shown.
      // this.$nextTick(() => {
      //   this.showMarkerArea()
      // })
    },
    readFile (file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.readAsDataURL(file)
      })
    },
    createImage (data) {
      return new Promise(resolve => {
        const img = document.createElement('img')
        img.onload = () => resolve(img)
        img.src = data
      })
    },
    showMarkerArea () {
      const dialogeImage = document.getElementById('dialogImage')
      const markerArea = new markerjs2.MarkerArea(dialogeImage)
      markerArea.renderAtNaturalSize = true
      markerArea.renderImageType = 'image/jpeg'
      markerArea.settings.displayMode = 'popup'
      // attach an event handler to assign annotated image back to our image element
      markerArea.addRenderEventListener((dataUrl, state) => {
        dialogeImage.src = dataUrl
        this.imageSrc = dataUrl
      })
      // launch marker.js
      markerArea.show()
    },
    createIssue () {
      const scope = this
      this.$validator.validateAll().then(function (res) {
        if (res) {
          const newIssue = {
            description: JSON.parse(JSON.stringify(scope.description)),
            dataJSON: JSON.parse(JSON.stringify(scope.dataJSON)),
            saving: false,
            imageSrc: JSON.parse(JSON.stringify(scope.imageSrc)),
            id: GUID.generateUUID(),
            areaId: JSON.parse(JSON.stringify(scope.areaId)),
            saveSuccesfull: null
          }
          if (scope.parentId) {
            newIssue.parentId = JSON.parse(JSON.stringify(scope.parentId))
          }

          if (!scope.parentId && scope.emergency === false) {
            newIssue.type = 'reactive'
          } else if (scope.emergency === true) {
            newIssue.type = 'emergency'
          }

          scope.issues.splice(0, 0, newIssue)
          scope.imageDialog = false
          scope.description = ''
          scope.saveIssue(newIssue)
        }
      })
    },
    /**
     * Delete an issue and then remove it from list.
     * @param {*} issue Issue to delete.
     * @param {*} idx Index in list.
     */
    deleteIssue (issue, idx) {
      const scope = this
      this.$refs.confirm.open('message.deleteIssue', 'message.deleteIssue', { color: 'primary' }).then(async (confirm) => {
        if (confirm) {
          try {
            issue.saveSuccesfull = null
            issue.saving = true
            await scope.$store.dispatch('issues/deleteIssue', issue.id)
            issue.saving = false
            scope.issues.splice(idx, 1)
          } catch (err) {
            scope.$snotify.error(err.message)
          } finally {
            issue.saving = false
          }
        }
      })
    },
    changeStep () {
      if (this.issueList === true) {
        const scope = this
        this.$router.push('/createIssue?parentId=' + this.issueListObject.id + '&areaId=' + this.areaId)
        // to make sure url has changed
        this.$nextTick(() => {
          setTimeout(function () {
            if (scope.$route.query.parentId) {
              scope.parentId = scope.$route.query.parentId
            }
          }, 500)
        })
        this.step = 2
      } else {
        this.step = 2
        this.parentId = null
      }
    },
    /**
     * Save the new issue, first put any files in S3.
     * @param {*} newIssue
     */
    async saveIssue (newIssue) {
      newIssue.saving = true

      // Save files to S3.
      newIssue.filesJSON = []
      const blob = await this.getBlob(newIssue.imageSrc)
      const uploadFile = await this.uploadFile({}, blob)
      newIssue.filesJSON.push(uploadFile)

      try {
        const createdIssue = await this.$store.dispatch('issues/createIssue', newIssue)
        // Use the new id from server.
        newIssue.id = createdIssue.id
        if (createdIssue.outOfHours) {
          console.log('out of hours')
          this.$refs.outOfHours.open('Out of Hours', createdIssue.outOfHoursMessage, { color: 'primary', confirm: this.$t('button.confirm') }).then((confirm) => {
            if (confirm) {
              newIssue.saving = false
              newIssue.saveSuccesfull = true
            }
          })
        } else {
          newIssue.saving = false
          newIssue.saveSuccesfull = true
        }
      } catch (err) {
        this.$snotify.error(err.message)
        newIssue.saveSuccesfull = false
      } finally {
        newIssue.saving = false
      }
    },
    /**
     * Upload file to S3.
     * @param {*} processedFile
     * @param {*} blob
     */
    async uploadFile (processedFile, blob) {
      const guid = GUID.generateUUID()

      // Resize file if it's bigger than opts here.
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        maxIterations: 2
      }

      const compressedFile = await imageCompression(blob, options)
      // Put the file in S3.
      await Storage.put('issues/' + guid + processedFile.name, compressedFile, {
        level: 'public',
        progressCallback (progress) {
          // scope.fileUploading.upload_percentage = Math.round((progress.loaded / progress.total) * 100)
        }
      })

      // Create the object for filesJSON
      return { level: 'public', key: 'issues/' + guid + processedFile.name }
    },
    async getBlob (data) {
      return fetch(data).then(r => r.blob())
    }
  }
}
